.heading {
  border: 1px solid #ddd5d5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 2%);
  font-size: 1.1rem;
  font-weight: 700;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add_category_btn {
  float: right;
  background-color: #21c286;
  color: #fff;
  font-weight: 600;
  top: 13px;
  right: 20px;
  padding: 9px;
}
.edit_profile_btn {
  float: right;
  background-color: #21c286 !important;
  color: #fff;
  font-weight: 600;
  top: 15px;
  right: 20px;
  padding: 9px;
  border: none !important;
  position: relative;
}
.edit_profile_btn.show_timing {
  float: left;
  margin-left: 1.8rem;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100% !important;
  border-radius: 10px;
}
