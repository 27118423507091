.file_upload .MuiListItem-button:hover,
svg:hover {
  fill: rgb(104, 104, 104);
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(0, 0, 0);
}
label.file_upload {
  border: 2px solid #c1c1c1;
  border-style: dotted;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 17px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.upload_image.css-i4bv87-MuiSvgIcon-root {
  width: 43px;
  height: 100%;
  fill: rgb(104, 104, 104);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  width: 100%;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.upload_image.css-vubbuv,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.upload_image.css-vubbuv {
  width: 44px;
  height: 100px;
  fill: rgb(104, 104, 104);
}
.img_preview {
  width: 100%;
  margin-bottom: 0%;
}

.submit_loader {
  width: 20px !important;
  height: 20px !important;
}
.submit_loader svg {
  color: #050000;

}
