.order {
  border: 1px solid #ddd5d5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 2%);
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
}

.img_alot {
  position: relative;
  right: 20px;
  width: 136px;
  margin-bottom: 22px;
}

.text_green {
  color: #21c286;
  font-size: 14px;
  padding-left: 25px;
}

.heading_order p {
  font-size: 15px;
  color: #808080fa;
  font-weight: normal;
}

.heading_order h6 {
  font-size: 20px;
  font-weight: 700;
}

.items_container {
  display: flex;
  justify-content: flex-start;
}

.items_container h5 {
  font-size: 17px;
  padding-top: 12px;
  padding-left: 20px;
}

.items_container div:first-child {
  flex-basis: 10%;
  padding-right: 14px;
}

.items_container div img {
  width: 100%;
  border-radius: 8px;
  height: 80px;
}

.items_container div:last-child {
  flex-basis: 90%;
}

.items_container div p {
  font-weight: 500;
  padding-left: 20px;
  font-size: 17px;
}

.items_container div p span {
  font-weight: 700;
  float: right;
  clear: both;
}

.items_heading {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  padding-top: 16px;
}

.items_total h5 {
  color: grey;
  font-size: 14px;
}

.items_total p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
}

.btn_submit {
  display: block;
  margin-left: auto;
  margin-top: 38px;
  background-color: #21c286;
  border-color: #21c286;
}