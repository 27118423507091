.profile {
  border: 1px solid #ddd5d5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 2%);
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  margin-top: 20px;
}
.profile .profile_img {
  flex-basis: 13%;
  padding-right: 22px;
}
.profile .profile_img img {
  width: 100%;
}
.profile_info h1 {
  font-size: 23px;
  font-weight: 800;
}
.profile_info h5:first-child {
  padding-top: 15px;
}
.profile_info h5 {
  font-size: 16px;
  font-weight: 700;
}
.edit_profile_form .form-label {
  color: #000 !important;
  font-weight: 600;
}
.edit_profile_form input {
  padding: 12px;
  border-radius: 12px;
  font-size: 1em;
}
