.MuiIconButton-root:hover {
  background-color: none;
}
button:focus {
  border: none;
  background-color: transparent;
}
.tab_btn,
.tab_btn1 {
  position: absolute;
  top: 20px;
  z-index: 1;

  background: transparent !important;
  color: #000 !important;
  border: none !important;
  border-radius: 0 !important;
  width: 116px;
}
.tab_btn {
  left: 24px;
}
.tab_btn1 {
  left: 171px;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: inherit !important;
  outline: none !important;
}

.active {
  border-bottom: 2px solid #21c286 !important;
}
