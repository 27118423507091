.login_left_side.col {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(45deg, #23c379, #1dc299) !important;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.logo_login_icon {
  width: 40%;
}

.login_right_side.col {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
p.login_heading {
  font-size: 1.3rem;
  font-weight: 700;
}
button.login_button.btn.btn-primary {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  background-image: linear-gradient(45deg, #23c379, #1dc299);
  border: none;
  font-weight: 600;
  font-size: 1.1em;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-image: linear-gradient(45deg, #23c379, #1dc299);
}

.login_right_side input {
  padding: 10px;
  border-radius: 8px;
  font-size: 0.8rem;
}

.form-label {
  font-weight: 500;
  font-size: 0.9rem;
}

.login_right_side input [type="email"] {
  background-color: #ffffff !important;
}
input:-webkit-autofill {
  background-color: #fff !important;
}
input[type="email"]:focus {
  background-color: #fff;
}
.login_right_side span .sign_up {
  color: rgb(30 194 143) !important;
  font-weight: 600;
  padding-left: 6px;
}
.login_right_side .account {
  font-size: 0.9rem;
}
.login_right_side .back_to_login {
  font-size: 0.9rem;
  text-decoration: underline;
}
.login_right_side .logo {
  display: none;
}
@media only screen and (max-width: 768px) {
  .login_left_side {
    display: none;
  }
  .login_right_side.col {
    background: #1ec293;
  }
  .login_right_side .w-75 {
    width: 100% !important;
  }
  .login_right_side .w-75 .account {
    margin-left: 4rem;
  }
  .login_right_side .logo {
    text-align: center;
    display: block;
  }
}
