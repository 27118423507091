.menu_icons {
  width: 40%;
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #21c286 !important;
}
img.sidebar_logo_icon {
  width: 62%;
  margin-top: 24px;
  margin: 24px auto 15px;
  display: block;
}
button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
}

.MuiListItem-button:hover,
svg:hover {
  fill: #fff;
  text-decoration: none;
  background-color: rgb(33 194 134) !important;
  color: #fff;
  /* border-radius: 8px; */
}
.menu_btn_active {
  fill: #fff;
  text-decoration: none;
  background-color: rgb(33 194 134) !important;
  color: #fff !important;
  /* border-radius: 8px !important; */
}
.MuiListItemIcon-root {
  color: #000 !important;
}
