@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap");

.table_action_icons {
  width: 24px;
  padding: 0 5px;
}
.submit,
.cancel {
  font-weight: 600 !important;
  padding: 10px 50px !important;
}
.submit {
  background-color: #21c286 !important;
  color: #fff !important;
}
.cancel {
  color: rgb(0, 0, 0) !important;

  background-color: #e4e4e4 !important;
}
.MuiTableSortLabel-root {
  font-weight: 600;
}
.delivered {
  color: #21c286 !important;
  font-weight: 500;
}
.form-control {
  font-size: 0.8rem !important;
}
textarea.form-control {
  border-radius: 10px !important;
}

svg.close_icon path {
  fill: red !important;
}

svg.add_slots path {
  fill: #21c286 !important;
}

.pay_btn {
  color: #fff !important;
  background: #21c286 !important;
  border-radius: 15px !important;
  padding: 3px 10px !important;
  align-items: center !important;
  font-size: 0.8rem !important;
}

.pay_btn.paid {
  background: #474747 !important;
}
